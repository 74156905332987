//
// BrowserStorage class
//
// Description: Enables CRUD against session or local storage
// ==========================================================

export enum StorageType {
    SESSION = 'session',
    LOCAL = 'local',
}


export const REDIRECT_URL = `loginRedirectUrl`

// Latest visited page url
export const LATEST_VISITED_URL = `latestVisitedUrl`

export const LANGUAGE = `language`
// Token
export const TOKEN_OBJECT = `tokenObject`

// Auth related stuff
export const AUTH_STATE_CONTEXT = `authContext`

// User related stuff on high level
export const USER_STATE_CONTEXT = `userContext`

// Customers
export const CUSTOMER_STATE_CONTEXT = `customerContext`

// Environment globals
export const ENVIRONMENT_CONFIG = `env`

// Ignore unsupported browser warning
export const IGNORE_UNSUPPORTED_BROWSER_WARNING = `ignoreUnsupportedBrowserWarning`

class BrowserStorage {
    storage!: Storage

    constructor(storageType: StorageType) {
        if (storageType === StorageType.LOCAL)
            this.storage = window.localStorage
        if (storageType === StorageType.SESSION)
            this.storage = window.sessionStorage
    }
    getItem(key: string): string | null {
        return this.storage.getItem(key)
    }

    setItem(key: string, value: string) {
        this.storage.setItem(key, value)
    }

    removeItem(key: string) {
        return this.storage.removeItem(key)
    }

    clear() {
        this.storage.clear()
    }

    getAllKeys() {
        return Object.keys(this.storage)
    }

    multiGet(keys: string[]) {
        return keys.map(key => [key, this.storage.getItem(key)])
    }

    multiSet(keyValuePairs: string[][]) {
        keyValuePairs.forEach(keyValuePair =>
            this.storage.setItem(keyValuePair[0], keyValuePair[1])
        )
        return keyValuePairs.map(keyValuePair => [
            keyValuePair[0],
            this.storage.getItem(keyValuePair[0]),
        ])
    }
}

export default BrowserStorage