import {addClass} from "@frontendUtils/helpers";
import {removeClass} from "@frontendUtils/helpers";
import {rawSizes} from "@frontendConstants/rawSizes";

function toggleOpenMegaDropdown(id: string) {
    const megaDropdown = document.getElementById(id)
    megaDropdown?.classList.toggle('open')
    closeAllMegaDropdowns(id)
    if (megaDropdown?.classList.contains('open')) {
        addClass(document.body, 'backdrop-blur')
    } else {
        removeClass(document.body, 'backdrop-blur')
    }
}

function closeAllMegaDropdowns(exceptThisId?: string) {
    const smallDevice = window.innerWidth <= rawSizes.xl
    if (smallDevice) {
        return
    }

    const megaDropDowns = document.querySelectorAll('.mega-dropdown')
    megaDropDowns.forEach((megaDropdown) => {
        if (megaDropdown.id === exceptThisId) {
            return
        }
        megaDropdown.classList.remove('open')
        if (exceptThisId == null) {
            removeClass(document.body, 'backdrop-blur')
        }
    })
}

function HandleKeyUpEvent(e: any) {
    if (e.keyCode === 27) { // esc key
        setTimeout(() => {
            closeAllMegaDropdowns()
        }, 200)
    }
}

function HandleOutsideClick(e: any) {
    const megaMenu = document.querySelectorAll('.mega-dropdown');
    const containMegaMenu = Array.from(megaMenu).some(node => node.contains(e.target));
    const menuItem = document.querySelectorAll('.navigation-container__menu-item');
    const containMenuItem = Array.from(menuItem).some(node => node.contains(e.target));
    const mobileNav = document.querySelector('.navigation-container') as HTMLElement;
    const containMobileNav = mobileNav.contains(e.target);
    if (!containMegaMenu && !containMenuItem && !containMobileNav) {
        closeAllMegaDropdowns();
    }
}

document.addEventListener('keyup', HandleKeyUpEvent)
document.addEventListener('click', HandleOutsideClick)

document.addEventListener('DOMContentLoaded', function () {
    const menuItems = document.querySelectorAll('.navigation-container__menu-item');

    menuItems.forEach((menuItem) => {
        menuItem.addEventListener('click', (event) => {
            const smallDevice = window.innerWidth <= rawSizes.xl
            if (smallDevice) {
                return
            }
            const target = event.currentTarget as HTMLElement;
            const dropdownId = target.dataset.dropdownId;
            if (dropdownId) {
                toggleOpenMegaDropdown(dropdownId);
            }
        });
        menuItem.addEventListener('keyup', (event) => {
            const smallDevice = window.innerWidth <= rawSizes.xl
            if (smallDevice) {
                return
            }
            const keyBoardEvent = event as KeyboardEvent;
            if (keyBoardEvent.key === 'Enter') {
                const target = event.currentTarget as HTMLElement;
                const dropdownId = target.dataset.dropdownId;
                if (dropdownId) {
                    toggleOpenMegaDropdown(dropdownId);
                }
            }

        });
    });
});